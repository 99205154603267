<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <div class="text-overline">Поточна інкасація:</div>
        <EncashmentInfo :encashment="encashment" :current-company-id="currentCompanyId" />
        <div v-if="encashment.comment" class="d-flex align-baseline custom-field">
          <v-textarea
            v-model="encashment.comment"
            class="my-3"
            height="80"
            label="Коментар"
            outlined
            no-resize
            disabled
            hide-details
          ></v-textarea>
        </div>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="12" sm="6" :class="{ 'text--disabled': !prevEncashment.id }">
        <div class="text-overline">Попередня інкасація:</div>
        <EncashmentInfo :encashment="prevEncashment" :current-company-id="currentCompanyId" />
        <div v-if="prevEncashment.prev_encashment_id" class="d-flex align-baseline">
          <router-link
            :to="{ name: 'EncashmentCard', params: { id: currentCompanyId, encashmentId: prevEncashment.prev_encashment_id } }"
            class="text-decoration-none font-weight-medium"
          >
            Переглянути попередню інкасацію
          </router-link>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-row>
      <v-col cols="12" sm="6">
        <div class="text-overline">Купюри, що були прийняти в період інкасації:</div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Номінал купюри</th>
                <th class="text-left">Кількість з інкасації, шт.</th>
                <th class="text-left">Перерахунок (за потреби) , шт.</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in NOTES" :key="item.property">
                <td>{{ item.title }}</td>
                <td>{{ encashment.notes_and_coins[0].notes[item.property] }}</td>
                <td>{{ encashment.actual_notes_and_coins[0].notes[item.property] }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" sm="6"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { NOTES } from '@/const/encashment-coins.enum'
import EncashmentInfo from '@/components/finance/encashments/EncashmentInfo.vue'

export default {
  name: 'EncashmentCardView',
  components: { EncashmentInfo },

  props: {
    encashment: {
      type: Object,
      required: true,
      default: () => {},
    },
    prevEncashment: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentCompanyId: {
      required: true,
      default: null,
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    NOTES: NOTES,
  }),
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 500px !important;
}
</style>
